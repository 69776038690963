import { template as template_54341ec7e1d744c4ac02456c038a6b5d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_54341ec7e1d744c4ac02456c038a6b5d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
