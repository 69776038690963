import { template as template_3fbbf3861b6b40c085dd45e15ea6f50e } from "@ember/template-compiler";
const FKControlMenuContainer = template_3fbbf3861b6b40c085dd45e15ea6f50e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
