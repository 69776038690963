import { template as template_676166e88e6a4477b2e9f8fbd2942e05 } from "@ember/template-compiler";
const SidebarSectionMessage = template_676166e88e6a4477b2e9f8fbd2942e05(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
